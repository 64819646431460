import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AttendanceComponent } from './attendance.component';

// /user
const routes: Routes = [
  { path: '', component: AttendanceComponent, },
  { path: 'panel', loadChildren: () => import('../../../app/modules/attendance/attendance-panel/attendance-panel.module').then(m => m.AttendancePanelModule) },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AttendanceRoutingModule { }
