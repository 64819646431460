<div>
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#D1FADF" />
    <rect width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path
      d="M14.9338 20.5H25.9338V22.5H14.9338V20.5ZM14.9338 18.5H25.9338V16.5H14.9338V18.5ZM14.9338 26.5H21.9338V24.5H14.9338V26.5ZM29.9438 23.37L30.6538 22.66C31.0438 22.27 31.6738 22.27 32.0638 22.66L32.7738 23.37C33.1638 23.76 33.1638 24.39 32.7738 24.78L32.0638 25.49L29.9438 23.37ZM29.2338 24.08L23.9338 29.38V31.5H26.0538L31.3538 26.2L29.2338 24.08Z"
      fill="#039855" />
  </svg>

  <h1 class="font-black text-[20px] text-black mt-2.5">Histórico de atendimento - {{pager.list[0]?.contact?.name}}
    {{pager.list[0]?.contact?.phone}}</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Veja abaixo todas as interações e mensagens trocadas durante o
    atendimento</h6>
</div>

<div style="height: 100%; overflow-y: auto">
  <mat-accordion>
    <div class="header-row grid grid-cols-7 gap-4 bg-[#F9FAFB] table-title">
      <p class="ml-[25px]">Status</p>
      <p class="ml-[50px]">Usuário</p>
      <p class="ml-[65px]">Departamento</p>
      <p class="ml-[87px]">TME</p>
      <p class="ml-[110px]">TMA</p>
      <p class="ml-[135px]">TMTA</p>
    </div>
    <mat-expansion-panel *ngFor="let attendance of pager.list" hideToggle>
      <mat-expansion-panel-header (click)="updateMessages(attendance._id)">
        <mat-panel-description>
          <p *ngIf="attendance.status === 'CLOSED'"> {{ attendance.updatedAt| date:'dd/MM/yyyy HH:mm' }} </p>
          <span *ngIf="attendance.status === 'IN_PROGRESS'">
          </span>
          <span *ngIf="attendance.status === 'OVERTIME'">
            <span class="px-[10px] py-[5px] font-light text-white rounded-[5px]"
              [ngStyle]="{'background-color': '#EF4444'}">
              Fora de horário
            </span>
          </span>
          <span *ngIf="attendance.status === 'PENDING'">
            <span class="px-[10px] py-[5px] font-dark text-white rounded-[5px]"
              [ngStyle]="{'background-color': 'orange'}">
              Aguardando
            </span>
            <span *ngIf="attendance.chatbotStatus.automated"
              class="ml-2 px-[10px] py-[5px] font-dark text-white rounded-[5px]"
              [ngStyle]="{'background-color': 'black'}">
              Chatbot
            </span>
          </span>
          <span *ngIf="attendance.status === 'IN_PROGRESS'">
            <span class="px-[10px] py-[5px] font-light text-white rounded-[5px]"
              [ngStyle]="{'background-color': 'green'}">
              Em andamento
            </span>
          </span>
          <span *ngIf="attendance.status === 'PAUSED'">
            <span class="px-[10px] py-[5px] font-light text-white rounded-[5px]"
              [ngStyle]="{'background-color': '#3B82F6'}">
              Pausado
            </span>
          </span>
        </mat-panel-description>
        <mat-panel-description>
          <img class="w-8 h-8 rounded-full" [src]="getUserImage(attendance?.user)" alt="{{attendance?.user?.name}}">
          <p class="ml-3">{{attendance?.user?.name ?? '-'}}</p>
        </mat-panel-description>
        <mat-panel-description>
          <p class="text-xs text-gray-500">{{attendance?.department?.name ?? '-'}}</p>
        </mat-panel-description>
        <mat-panel-description>
          <p class="text-xs text-gray-500">{{getFormatDate(attendance?.kpis?.tme ) ?? '-'}}</p>
        </mat-panel-description>
        <mat-panel-description>
          <p class="text-xs text-gray-500">{{getFormatDate(attendance?.kpis?.tma) ?? '-'}}</p>
        </mat-panel-description>
        <mat-panel-description>
          <p class="text-xs text-gray-500">{{getFormatDate(attendance?.kpis?.tmta) ?? '-'}}</p>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <chat-messages-component [attendance]="attendance" [messages]="messages"></chat-messages-component>
      
    </mat-expansion-panel>
  </mat-accordion>

</div>
<paginator *ngIf="pager.total > pager.perPage" class="flex justify-content-center mt-3 mb-3" [pager]="pager"
  (onPager)="loadPage($event)">
</paginator>