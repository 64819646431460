import { Component, ElementRef, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractComponent, Attendance, AttendanceService, Message, MessageService, Pager, UtilHelper } from 'lib-trend-core';
import { takeUntil } from 'rxjs';

export interface AttendanceHistoryData {
  idContact: string;
  idAttendance: string;
}

@Component({
  templateUrl: 'attendance-history.component.html',
  styleUrls: ['./attendance-history.component.scss']
})
export class AttendanceHistoryComponent extends AbstractComponent implements OnInit {

  @ViewChild('messageContainer') messageContainer: ElementRef<HTMLDivElement>;

  pager: Pager<Attendance> = new Pager<Attendance>({ perPage: 11 });
  attendances: Attendance[] = [];
  filteredAttendances: Attendance[] = [];

  messages: Array<Message> = new Array<Message>();

  constructor(
    public injector: Injector,
    private attendanceService: AttendanceService,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: AttendanceHistoryData,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.searchParams = {
      company: super.getIDCurrentCompany(),
      contact: this.data.idContact,
    };
    this.attendanceService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (pager: Pager<Attendance>) => {
          this.pager = pager;
        },
      })
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  formatWhatsappMessage(text: string): string {
    return UtilHelper.formatWhatsappMessage(text);
  }

  updateMessages(idAttendance: string) {
    this.messageService.getByAttendance(idAttendance)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (messages: Array<Message>) => {
          this.messages = messages;
        },
      })
  }

  getFormatDate(tma: number): string {
    if (typeof tma !== 'number' || isNaN(tma) || tma < 0) {
      return '-';
    }
    const totalSeconds = tma * 60;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const secs = Math.floor(totalSeconds % 60);

    return `${hours}h ${minutes}m ${secs}s`;
  }


}
