

import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { AttendanceHistoryComponent } from './attendance-history.component';
import { ChatModule } from '../attendance-panel/attendance-panel-components/chat/chat.module';

@NgModule({
  imports: [
    SharedModule,
    ChatModule,
  ],
  declarations: [
    AttendanceHistoryComponent,
  ],
  exports: [
    AttendanceHistoryComponent,
  ]
})
export class AttendanceHistoryModule { }
