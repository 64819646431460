import { NgModule } from '@angular/core';
import { CardComponentModule, SharedModule } from 'lib-trend-core';
import { AttendanceHistoryModule } from './attendance-history/attendance-history.module';
import { AttendancePanelModule } from './attendance-panel/attendance-panel.module';
import { AttendanceRoutingModule } from './attendance-routing.module';
import { AttendanceComponent } from './attendance.component';

@NgModule({
  imports: [
    SharedModule,
    AttendanceRoutingModule,
    CardComponentModule,
    AttendancePanelModule,
    AttendanceHistoryModule,
  ],
  declarations: [
    AttendanceComponent,
  ],
  exports: [
    AttendanceComponent,
  ],
  providers: [],
  schemas: []
})
export class AttendanceModule { }
